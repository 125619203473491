import styles from './HighlightArticles.module.scss'
import { useGetHighlightsQuery } from '@/api/highlights'
import React from 'react'
import Image from 'next/image'
import Banner from '@/components/common/Banner/Banner'
import Button from '@/components/buttons/Button/Button'
import CartIcon from '@/assets/svg/cart.svg'
import { useTranslation } from 'next-i18next'
import useAddToCart from '@/utils/hooks/useAddToCart'
import usePrice from '@/utils/hooks/usePrice'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import { selectCurrency } from '@/features/app/appSlice'
import { ArticleModel } from '@/types/api'
import Link from 'next/link'
import { convertToURL } from '@/utils/helpers'
import useLocalizedLink from '@/utils/hooks/useLocalizedLink'

type Props = {}

type ArticleProps = {
  item: ArticleModel
}

const Article = ({
  item,
}: ArticleProps) => {
  const { t } = useTranslation()
  const { addToCart } = useAddToCart()
  const { price } = usePrice()
  const currency = useAppSelector(selectCurrency)
  const getLocalizedLink = useLocalizedLink()

  const onClickBuy = () => {
    addToCart({
      articleId: item.id,
      quantity: 4,
      articleText: item.articleText,
      price: item.price,
    })
  }

  return (
    <div className={styles.article}>
      {item.isNew && (
        <div className={styles.banner}>
          <Banner
            text={t('search.news')}
          />
        </div>
      )}
      <div className={styles.image}>
        <Image
          src={`${process.env.NEXT_PUBLIC_API}/images/${item.imageId}`}
          alt={item.articleText}
          width={425}
          height={425}
          style={{ objectFit: 'contain' }}
          loading="lazy"
        />
      </div>
      <div className={styles.info}>
        {
          <div className={styles.type}>{item.regularPrice ? t('search.campaign') : t('search.news')}</div>
        }
        <h2 className={styles.brand}>{item.brandName}</h2>
        <h3 className={styles.model}>{item.modelName}</h3>
        <p>{item.articleText}</p>
        <div className={styles.price}>
          <div className={styles.currentPrice}>
            {price(item.price)} <span>{currency}</span>
          </div>
          {item.regularPrice && (
            <div className={styles.regularPrice}>
              {t('shop:regularPrice')} {price(item.regularPrice, true)}
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          <Link href={getLocalizedLink(item.productLink)}>
            <Button
              darkGradient={true}
              rounded={true}
              label={t('search.info')}
              arrow={true}
              medium={true}
              width={'99px'}
            />
          </Link>
          <Button
            rounded={true}
            label={t('search.buy')}
            icon={CartIcon}
            iconPosition="right"
            onClick={onClickBuy}
            medium={true}
            width={'99px'}
          />
        </div>
      </div>
    </div>
  )
}

const HighlightArticles = (props: Props) => {
  const { data } = useGetHighlightsQuery()

  return (
    <div className={styles.component}>
      {data?.map((highlight) => (
        <Article
          key={highlight.id}
          item={highlight}
        />
      ))}
    </div>
  )
}

export default HighlightArticles