import useLocalizedPath from "./useLocalizedPath"

const useLocalizedLink = () => {
  const getLocalizedPath = useLocalizedPath()

  const getLocalizedLink = (path: string): string => {
    return path.split('/').map(p => getLocalizedPath(p, false)).join('/')
  }

  return getLocalizedLink
}

export default useLocalizedLink